import router from "@/plugins/router";
import { useFlowStore } from "@/plugins/pinia/stores/flow";

const pagesMap = {
  LI: "Instruction Page",
  LHP: "Position Page",
  LS: "Short Page",
  LD: "Distance Page",
  F2F: "F2F Page",
  RP: "Result Page",
  SP: "Sessions Page",
};
export const setFlow = (flow) => {
  const flowStore = useFlowStore();
  const flowCode = flow.code;
  const queue = flowCode.split("-");
  if (queue.includes("LHP") || queue.includes("LD") || queue.includes("LS")) {
    queue.unshift("LI");
  }
  queue.push("RP");
  queue.push("SP");
  flowStore.setFlow(queue);
};

export const nextStep = async () => {
  const flowStore = useFlowStore();
  const nextStep = flowStore.flow[0];
  await router.push({ name: pagesMap[nextStep] });
  if (flowStore.flow.length === 0) {
    await router.push({ name: "Result Page" });
  }
  await flowStore.flow.shift();
};

export const abortFlow = async () => {
  const flowStore = useFlowStore();
  const nextStep = "RP";
  await router.push({ name: pagesMap[nextStep] });
  flowStore.setFlow([]);
};
