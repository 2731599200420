import { defineStore } from "pinia";

export const useFlowStore = defineStore({
  id: "flowStore",
  state: () => ({
    flow: [],
  }),
  actions: {
    setFlow(value) {
      this.flow = value;
    },
  },
});
