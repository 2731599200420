<template>
  <position-frame v-bind="stepper.current" />
</template>

<script setup>
import { toRefs } from "vue";
import PositionFrame from "@/components/position/PositionFrame.vue";
import useStepper from "@/utils/stepper";

// eslint-disable-next-line no-undef
const emit = defineEmits(["finish"]);

const hintRelations = {
  "Face not Found": "not-found",
  "Too Close": "too-close",
  "Too Far": "too-far",
};
const relateResponseToHint = (response) => {
  if (response in hintRelations) return hintRelations[response];
  return "none";
};

const stepper = useStepper({
  queue: [
    "forward",
    "forward",
    "forward",
    "forward",
    "forward",
    "left",
    "right",
  ],
  steps: {
    forward: {
      position: "forward",
      state: "neutral",
      header: "Смотрите прямо",
    },
    left: {
      position: "left",
      state: "neutral",
      header: "Слегка поверните голову налево",
    },
    right: {
      position: "right",
      state: "neutral",
      header: "Слегка поверните голову направо",
    },
  },
  onEmpty: (context) => {
    context.current.state = "success";
    emit("finish", "success");
  },
  onTimeout: (context) => {
    context.current.state = "failure";
    emit("finish", "failure");
  },
  onTransition: (context) => {
    context.current.state = "success";
    context.current.hint = "none";
  },
  onFailure: (context) => {
    context.current.hint = relateResponseToHint(context.givenStep);
  },
});

// eslint-disable-next-line no-undef
defineExpose(toRefs(stepper));
</script>
