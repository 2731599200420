<template>
  <div :class="$style['container']">
    <video
      :ref="(ref) => (video.ref = ref)"
      :class="{
        [$style['video']]: true,
        [$style['mirror']]: video.mirror,
      }"
      autoplay
      playsinline
    />
    <div :class="$style['controls']">
      <button :class="$style['controls__control']" @click="toggleMirror">
        <mirror-icon />
      </button>
      <button :class="$style['controls__control']" @click="switchCamera">
        <switch-icon />
      </button>
      <button :class="$style['controls__control']" @click="stopCamera">
        <close-icon />
      </button>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  reactive,
  onMounted,
  onBeforeUnmount,
  getCurrentInstance,
} from "vue";
import MirrorIcon from "@/components/svg/video/MirrorIcon.vue";
import SwitchIcon from "@/components/svg/video/SwitchIcon.vue";
import CloseIcon from "@/components/svg/video/CloseIcon.vue";
import { getOperationSystem, getDevices } from "@/utils/navigator";
import Canvas from "@/utils/canvas";
import { getMediaResolution } from "@/utils/resolution";
import { detectMobile } from "@/utils/mobile";

// eslint-disable-next-line no-undef
const props = defineProps({
  side: {
    type: String,
    default() {
      return "front";
    },
  },
});
// eslint-disable-next-line no-undef
const emit = defineEmits({
  start: () => true,
  stop: () => true,
  error: () => true,
});

const video = reactive({
  ref: null,
  mirror: false,
});
const canvas = ref(null);
const devices = ref(null);
const deviceIds = ref(null);
const instance = ref(null);

onMounted(async () => {
  instance.value = getCurrentInstance();
  try {
    devices.value = await getDevices();
    if (devices.value.length === 0) throw new Error("Device list is empty");
    deviceIds.value = devices.value.map((device) => device.deviceId);
    const deviceId =
      props.side === "back" ? deviceIds.value[0] : deviceIds.value[1];
    await setStream(deviceId);
    canvas.value = new Canvas({
      video: video.ref,
    });
    emit("start");
  } catch (e) {
    emit("error");
  }
});

onBeforeUnmount(() => {
  stopStream();
});

async function setStream(deviceId) {
  const mobile = detectMobile();
  let constraints = { width: 1280, height: 720 };
  if (!mobile) {
    constraints = getMediaResolution(instance.value.appContext.app._container);
  }
  stopStream();
  video.ref.srcObject = await navigator.mediaDevices.getUserMedia({
    video: { ...constraints, deviceId, resizeMode: "none" },
    audio: false,
  });
  const isMainApp = instance.value.appContext.app._container.id === "app";
  const viewportHeight = window.innerHeight;
  instance.value.appContext.app._container.style = isMainApp
    ? `width: 100vw; height: ${viewportHeight}px; max-height: -webkit-fill-available;`
    : "width: 100%; height: 100%";
  setMirror();
}

function stopStream() {
  if (video.ref.srcObject) {
    const tracks = video.ref.srcObject.getTracks();
    tracks.forEach((track) => track.stop());
    video.ref.srcObject = null;
  }
}

function setMirror() {
  if (["Android", "iOS"].includes(getOperationSystem())) {
    const deviceIndex = deviceIds.value.indexOf(
      video.ref.srcObject.getVideoTracks()[0].getSettings().deviceId
    );
    if (deviceIndex === 0) toggleMirror("back");
    else if (deviceIndex === 1) toggleMirror("front");
  } else toggleMirror("front");
}

function toggleMirror(side = null) {
  if (side === "front") video.mirror = true;
  else if (side === "back") video.mirror = false;
  else video.mirror = !video.mirror;
}

function switchCamera() {
  if (video.ref.srcObject && deviceIds.value.length > 1) {
    const deviceId = video.ref.srcObject
      .getVideoTracks()[0]
      .getSettings().deviceId;
    const deviceIndex = deviceIds.value.indexOf(deviceId);
    const newDeviceId =
      deviceIndex === deviceIds.value.length - 1
        ? deviceIds.value[0]
        : deviceIds[deviceIndex + 1];
    setStream(newDeviceId);
  }
}

function stopCamera() {
  stopStream();
  emit("stop");
}

// eslint-disable-next-line no-undef
defineExpose({
  screenshot: (config) => {
    return canvas.value.screenshot(config);
  },
  stop: () => {
    stopCamera();
  },
  settings: () => {
    const settings = video.ref.srcObject.getTracks()[0].getSettings();
    return {
      width: settings.width,
      height: settings.height,
    };
  },
});
</script>

<style module>
.container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

video {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

video.mirror {
  transform: scaleX(-1);
}

.controls {
  position: absolute;
  right: 0;
  top: 0;
  margin: 5px;
  z-index: 9999;
}

.controls__control {
  padding: 5px 10px;
  width: auto;
  height: auto;
  margin: 10px;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.4);
  border: none;
  border-radius: 10px;
  outline: none;
}

.controls__control svg {
  width: 32px;
  height: 32px;
}
</style>
