import { instance } from "@/plugins/axios";

export const getAllSessions = async (limit, offset) => {
  return await instance.get("/flow/sessions/", { params: { limit, offset } });
};

export const createSession = async () => {
  return await instance.post("/flow/sessions/");
};

export const getSession = async (session) => {
  return await instance.get(`/flow/sessions/${session}/`);
};
