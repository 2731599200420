import { defineStore } from "pinia";

export const useUserStore = defineStore({
  id: "userStore",
  state: () => ({
    livenessResult: {},
    F2FResult: {},
  }),
  actions: {
    setLivenessResult(value) {
      this.livenessResult = value;
    },
    setF2FResult(value) {
      this.F2FResult = value;
    },
    resetStore() {
      this.livenessResult = {};
      this.F2FResult = {};
    },
  },
});
