<template>
  <distance-frame v-bind="stepper.current" />
</template>

<script setup>
import { toRefs } from "vue";
import DistanceFrame from "@/components/distance/DistanceFrame.vue";
import useStepper from "@/utils/stepper";

// eslint-disable-next-line no-undef
const emit = defineEmits(["finish"]);

const stepper = useStepper({
  queue: ["further", "further", "closer"],
  steps: {
    further: {
      size: "small",
      state: "neutral",
      header: "Встаньте дальше, и поместите голову в центр овала",
    },
    closer: {
      size: "big",
      state: "neutral",
      header: "Встаньте ближе, и поместите голову в центр овала",
    },
  },
  onEmpty: (context) => {
    context.current.state = "success";
    emit("finish", "success");
  },
  onTimeout: (context) => {
    context.current.state = "failure";
    emit("finish", "failure");
  },
  onTransition: (context) => {
    context.current.state = "success";
    context.current.hint = "none";
    setTimeout(() => {
      context.current.state = "neutral";
    }, 500);
  },
});

// eslint-disable-next-line no-undef
defineExpose(toRefs(stepper));
</script>
