export async function getDevices() {
  function stopStream(stream) {
    const tracks = stream.getTracks();
    tracks.forEach((track) => track.stop());
  }

  const operationSystem = getOperationSystem();
  const stream = await navigator.mediaDevices.getUserMedia({
    video: true,
    audio: false,
  });
  let devices = [];

  try {
    const enumerateDevices = await navigator.mediaDevices.enumerateDevices();
    if (operationSystem === "Android")
      devices = getAndroidDevices(enumerateDevices);
    else if (operationSystem === "iOS")
      devices = getIOSDevices(enumerateDevices);

    if (!devices.length) devices = getDefaultDevices(enumerateDevices);
  } catch (err) {
    console.log(err.name + ": " + err.message);
  }

  stopStream(stream);
  return devices;
}

function getAndroidDevices(enumerateDevices) {
  const devices = [];

  for (const device of enumerateDevices) {
    const labelArray = device.label.split(" ");
    if (device.kind === "videoinput") {
      const cameraNumber = labelArray[1][0];
      if (cameraNumber === "0" || cameraNumber === "1") {
        if (labelArray[3] === "front") devices[1] = device;
        else if (labelArray[3] === "back") devices[0] = device;
      }
    }
  }

  return devices;
}

function getIOSDevices(enumerateDevices) {
  const devices = [];

  for (const device of enumerateDevices) {
    if (device.kind === "videoinput") {
      const labelArray = device.label.split(" ");
      if (labelArray.length === 2) {
        const cameraLabel = labelArray[0];
        if (cameraLabel === "Front") devices[1] = device;
        else if (cameraLabel === "Back") devices[0] = device;
      } else {
        const cameraLabel = labelArray[2];
        if (cameraLabel === "передней") devices[1] = device;
        else if (cameraLabel === "задней") devices[0] = device;
      }
    }

    if (devices.length === 0) {
      for (const device of enumerateDevices) {
        if (device.kind === "videoinput") {
          if (device.label.toLowerCase() === "передняя камера")
            devices[1] = device;
          else if (device.label.toLowerCase() === "задняя камера")
            devices[0] = device;
        }
      }
    }
  }

  return devices;
}

function getDefaultDevices(enumerateDevices) {
  const virtualKeywords = [
    "virtual",
    "obs",
    "xsplit",
    "camtwist",
    "manycam",
    "snap camera",
    "fake",
    "streamlabs",
    "virtualcam",
    "e2esoft vcam",
    "altercam",
    "splitcam",
    "webcamoid",
    "obs virtual camera",
    "manycam virtual cam",
    "xsplit vcam",
    "snap camera virtual cam",
    "snap",
    "capture",
    "vcam",
    "virtual webcam",
    "виртуальная камера",
    "виртуальная",
  ];
  const devices = [];

  for (const device of enumerateDevices) {
    const deviceLabelLower = device.label.toLowerCase();
    if (
      device.kind === "videoinput" &&
      !virtualKeywords.some((keyword) => deviceLabelLower.includes(keyword))
    ) {
      devices.push(device);
    }
  }

  return devices;
}

export function getOperationSystem() {
  const userAgent = window.navigator.userAgent;
  const platform = window.navigator.platform;
  const macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];
  const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];
  const iosPlatforms = ["iPhone", "iPad", "iPod"];

  let operationSystem = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    operationSystem = "Mac OS";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    operationSystem = "iOS";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    operationSystem = "Windows";
  } else if (/Android/.test(userAgent)) {
    operationSystem = "Android";
  } else if (/Linux/.test(platform)) {
    operationSystem = "Linux";
  }

  return operationSystem;
}
