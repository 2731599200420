import { defineStore } from "pinia";

export const useSessionStore = defineStore({
  id: "sessionStore",
  state: () => ({
    session: [],
  }),
  actions: {
    setSession(value) {
      this.session = value;
    },
  },
});
