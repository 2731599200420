import { ref, reactive, watch } from "vue";

export default function useStepper(config) {
  const queue = ref(config?.queue ?? []);
  const steps = config?.steps ?? {};
  const delay = config?.delay ?? 500;
  const timer = config?.timer ?? 35000;
  const events = {
    onShift: config?.onShift ?? (() => {}),
    onTimeout: config?.onTimeout ?? (() => {}),
    onNext: config?.onNext ?? (() => {}),
    onForce: config?.onForce ?? (() => {}),
    onTransition: config?.onTransition ?? (() => {}),
    onEmpty: config?.onEmpty ?? (() => {}),
    onFailure: config?.onFailure ?? (() => {}),
    onFinish: config?.onFinish ?? (() => {}),
  };

  const step = ref(queue.value.shift());
  const current = ref({ ...steps[step.value] });
  const timeout = { value: runTimeout() };
  const finished = { value: false };
  const tries = { value: 0 };

  const context = reactive({
    current,
    step,
    tries,
    givenStep: null,
    next(givenStep) {
      context.givenStep = givenStep;
      dispatch("next");
      if (finished.value === true) return true;
      tries.value++;
      if (step.value === givenStep) {
        dispatch("transition");
        setTimeout(() => {
          if (queue.value?.length > 0) {
            step.value = queue.value.shift();
            dispatch("shift");
          } else {
            clearTimeout(timeout.value);
            dispatch("empty");
          }
        }, delay);
        return queue.value.length === 0;
      }
      dispatch("failure");
      return false;
    },
    finish() {
      dispatch("finish");
      finished.value = true;
      clearTimeout(timeout.value);
    },
    force() {
      dispatch("force");
      context.next(step.value);
    },
    isLast: () => queue.value.length === 0,
  });

  watch(
    () => step.value,
    (newStep) => {
      current.value = { ...steps[newStep] };
      clearTimeout(timeout.value);
      timeout.value = runTimeout();
    }
  );

  function dispatch(type) {
    const fullname = `on${type.charAt(0).toUpperCase()}${type.slice(1)}`;
    events[fullname](context);
  }

  function runTimeout() {
    return setTimeout(() => {
      dispatch("timeout");
    }, timer);
  }

  return context;
}
