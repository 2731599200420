import { detectMobile } from "@/utils/mobile";

/**
 * Function takes one required argument `root` of type `HTMLElement`.
 *
 * Returns an object with `width` and `height` keys that contains available size for frame in pixels by default.
 *
 * Returning object example:
 * ```javascript
 * {
 *   width: {
 *     further: 0px,
 *     closer: 0px,
 *     default: 0px,
 *   },
 *   height: {
 *     further: 0px,
 *     closer: 0px,
 *     default: 0px
 *   }
 * }
 * ```
 * @param {HTMLElement} root - Root HTML element for which some styles will be written.
 * @param {Boolean} withPx - Returning values will be with 'px' unit value if true
 * @return {Object}
 */
export function getFrameResolution(root, withPx = true) {
  const mobile = detectMobile();
  if (mobile) {
    return {
      width: {
        further: withPx ? "280px" : 230,
        closer: withPx ? "380px" : 310,
        default: withPx ? `${(230 + 310) / 2}px` : (230 + 310) / 2,
      },
      height: {
        further: withPx ? "355px" : 305,
        closer: withPx ? "450px" : 380,
        default: withPx ? `${(305 + 380) / 2}px` : (305 + 380) / 2,
      },
    };
  }
  console.log("DESKTOP DETECTED");
  const rootHeight = toPx(root, window.getComputedStyle(root).height);

  const furtherHeight = Math.round(rootHeight / 1.4);
  const closerHeight = Math.round(rootHeight / 1.1);
  const defaultHeight = Math.round((furtherHeight + closerHeight) / 2.5);

  const furtherWidth = Math.round(furtherHeight / 1.2);
  const closerWidth = Math.round(closerHeight / 1.2);
  const defaultWidth = Math.round(defaultHeight / 1.2);

  return {
    width: {
      further: withPx ? furtherWidth + "px" : furtherWidth,
      closer: withPx ? closerWidth + "px" : closerWidth,
      default: withPx ? defaultWidth + "px" : defaultWidth,
    },
    height: {
      further: withPx ? furtherHeight + "px" : furtherHeight,
      closer: withPx ? closerHeight + "px" : closerHeight,
      default: withPx ? defaultHeight + "px" : defaultHeight,
    },
  };
}

export function getFrameArea(root, rootSize) {
  const getArea = (width, height) => {
    return (width / 2) * (height / 2) * Math.PI;
  };

  const areaRatio = (part, full) => {
    return (part / full) * 100;
  };

  const getDivergence = (values, divergence, step = "", bigResolution) => {
    const mobile = detectMobile();
    let minFrameArea;
    let maxFrameArea;
    if (!mobile) {
      values.width = values.width / (bigResolution ? 1.7 : 1.35);
      values.height = values.height / (bigResolution ? 1.7 : 1.35);

      let floatingNumberMin = 1;
      let floatingNumberMax = 1;

      if (step === "further") {
        floatingNumberMin = 2.6;
        floatingNumberMax = 0.4;
      }
      if (step === "closer") {
        floatingNumberMin = 1.3;
        floatingNumberMax = 0.6;
      }

      minFrameArea = getArea(
        values.width * ((100 - divergence * floatingNumberMin) / 100),
        values.height * ((100 - divergence * floatingNumberMin) / 100)
      );
      maxFrameArea = getArea(
        values.width * ((100 + divergence * floatingNumberMax) / 100),
        values.height * ((100 + divergence * floatingNumberMax) / 100)
      );
    } else {
      values.width = values.width * 2.1;
      values.height = values.height * 2.1;

      let floatingNumberMin = 1;
      let floatingNumberMax = 1;

      if (step === "further") {
        floatingNumberMin = 3.61;
        floatingNumberMax = 0.6;
      }
      if (step === "closer") {
        floatingNumberMin = 2.9;
        floatingNumberMax = 1.7;
      }

      minFrameArea = getArea(
        values.width * ((100 - divergence * floatingNumberMin) / 100),
        values.height * ((100 - divergence * floatingNumberMin) / 100)
      );
      maxFrameArea = getArea(
        values.width * ((100 + divergence * floatingNumberMax) / 123),
        values.height * ((100 + divergence * floatingNumberMax) / 123)
      );
    }
    const rootArea = parseInt((rootSize.height * rootSize.width).toString());

    const minArea = Math.round(areaRatio(minFrameArea, rootArea));
    const maxArea = Math.round(areaRatio(maxFrameArea, rootArea));

    return {
      min: minArea,
      max: maxArea,
    };
  };

  const frameResolution = getFrameResolution(root, false);
  const closerSize = {
    width: frameResolution.width.closer,
    height: frameResolution.height.closer,
  };
  const furtherSize = {
    width: frameResolution.width.further,
    height: frameResolution.height.further,
  };
  const defaultSize = {
    width: frameResolution.width.default,
    height: frameResolution.height.default,
  };
  const bigResolution = defaultSize.height > 640 && defaultSize.width > 530;
  const closerAreaRange = getDivergence(
    closerSize,
    10,
    "closer",
    bigResolution
  );
  const furtherAreaRange = getDivergence(
    furtherSize,
    10,
    "further",
    bigResolution
  );
  const defaultAreaRange = getDivergence(defaultSize, 10, bigResolution);
  return {
    closer: closerAreaRange,
    further: furtherAreaRange,
    default: defaultAreaRange,
  };
}

/**
 * @param {HTMLElement} root  Root HTML element for which some styles will be written.
 * @return {MediaTrackConstraints}
 */
export function getMediaResolution(root) {
  const mobile = detectMobile();
  const viewport = getViewport();
  if (mobile) return viewport;

  const ratio = viewport.width / viewport.height;

  let height = toPx(root, window.getComputedStyle(root).height);
  let width = toPx(root, window.getComputedStyle(root).width);

  if (height <= 480) {
    return {
      height: 480,
      width: 640,
    };
  } else if (height >= 1080) {
    return {
      height: viewport.height,
      width: viewport.width,
    };
  } else if (height >= viewport.height && width <= viewport.width) {
    height = width / ratio;
  } else if (height <= viewport.height && width >= viewport.width) {
    width = height * ratio;
  } else if (height >= viewport.height && width >= viewport.width) {
    return {
      height: viewport.height,
      width: viewport.width,
    };
  }
  return {
    height,
    width,
  };
}

function getViewport() {
  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );
  const vh = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
  );
  return {
    width: vw,
    height: vh,
  };
}

// Convert any CSS Unit to Pixels

let testElem = document.createElement("test");
const docElement = document.documentElement;
const defaultView = document.defaultView;
const getComputedStyle = defaultView && defaultView.getComputedStyle;
let computedValueBug;
const runit = /^(-?[\d+.-]+)([a-z]+|%)$/i;
const convert = {};
const conversions = [1 / 25.4, 1 / 2.54, 1 / 72, 1 / 6];
const units = ["mm", "cm", "pt", "pc", "in", "mozmm"];
let i = 6; // units.length

// add the test element to the dom
docElement.appendChild(testElem);

// test for the WebKit getComputedStyle bug
// @see http://bugs.jquery.com/ticket/10639
if (getComputedStyle) {
  // add a percentage margin and measure it
  testElem.style.marginTop = "1%";
  computedValueBug = getComputedStyle(testElem).marginTop === "1%";
}

// pre-calculate absolute unit conversions
while (i--) {
  convert[units[i] + "toPx"] = conversions[i]
    ? conversions[i] * convert.inToPx
    : toPx(testElem, "1" + units[i]);
}

// remove the test element from the DOM and delete it
docElement.removeChild(testElem);
testElem = undefined;

function toPx(elem, value, prop, force) {
  // use width as the default property, or specify your own
  prop = prop || "width";

  let style;
  let inlineValue;
  let ret;
  const unit = (value.match(runit) || [])[2];
  let conversion = unit === "px" ? 1 : convert[unit + "toPx"];
  const rem = /r?em/i;

  if (conversion || (rem.test(unit) && !force)) {
    // calculate known conversions immediately
    // find the correct element for absolute units or rem or fontSize + em or em
    elem = conversion
      ? elem
      : unit === "rem"
      ? docElement
      : prop === "fontSize"
      ? elem.parentNode || elem
      : elem;

    // use the pre-calculated conversion or fontSize of the element for rem and em
    conversion = conversion || parseFloat(curCSS(elem, "fontSize"));

    // multiply the value by the conversion
    ret = parseFloat(value) * conversion;
  } else {
    // begin "the awesome hack by Dean Edwards"
    // @see http://erik.eae.net/archives/2007/07/27/18.54.15/#comment-102291

    // remember the current style
    style = elem.style;
    inlineValue = style[prop];

    // set the style on the target element
    try {
      style[prop] = value;
    } catch (e) {
      // IE 8 and below throw an exception when setting unsupported units
      return 0;
    }

    // read the computed value
    // if style is nothing we probably set an unsupported unit
    ret = !style[prop] ? 0 : parseFloat(curCSS(elem, prop));

    // reset the style back to what it was or blank it out
    style[prop] = inlineValue !== undefined ? inlineValue : null;
  }

  // return a number
  return ret;
}

// return the computed value of a CSS property
function curCSS(elem, prop) {
  let value;
  let pixel;
  let unit;
  const rvpos = /^top|bottom/;
  const outerProp = [
    "paddingTop",
    "paddingBottom",
    "borderTop",
    "borderBottom",
  ];
  let innerHeight;
  let parent;
  let i = 4; // outerProp.length

  if (getComputedStyle) {
    // FireFox, Chrome/Safari, Opera and IE9+
    value = getComputedStyle(elem)[prop];
  } else if (
    (pixel = elem.style["pixel" + prop.charAt(0).toUpperCase() + prop.slice(1)])
  ) {
    // IE and Opera support pixel shortcuts for top, bottom, left, right, height, width
    // WebKit supports pixel shortcuts only when an absolute unit is used
    value = pixel + "px";
  } else if (prop === "fontSize") {
    // correct IE issues with font-size
    // @see http://bugs.jquery.com/ticket/760
    value = toPx(elem, "1em", "left", 1) + "px";
  } else {
    // IE 8 and below return the specified style
    value = elem.currentStyle[prop];
  }

  // check the unit
  unit = (value.match(runit) || [])[2];
  if (unit === "%" && computedValueBug) {
    // WebKit won't convert percentages for top, bottom, left, right, margin and text-indent
    if (rvpos.test(prop)) {
      // Top and bottom require measuring the innerHeight of the parent.
      innerHeight = (parent = elem.parentNode || elem).offsetHeight;
      while (i--) {
        innerHeight -= parseFloat(curCSS(parent, outerProp[i]));
      }
      value = (parseFloat(value) / 100) * innerHeight + "px";
    } else {
      // This fixes margin, left, right and text-indent
      // @see https://bugs.webkit.org/show_bug.cgi?id=29084
      // @see http://bugs.jquery.com/ticket/10639
      value = toPx(elem, value);
    }
  } else if (
    (value === "auto" || (unit && unit !== "px")) &&
    getComputedStyle
  ) {
    // WebKit and Opera will return auto in some cases
    // Firefox will pass back an unaltered value when it can't be set, like top on a static element
    value = 0;
  } else if (unit && unit !== "px" && !getComputedStyle) {
    // IE 8 and below won't convert units for us
    // try to convert using a prop that will return pixels
    // this will be accurate for everything (except font-size and some percentages)
    value = toPx(elem, value) + "px";
  }
  return value;
}
