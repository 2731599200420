<template>
  <div class="container">
    <div class="background">
      <p class="header">
        {{ props.header }}
      </p>
      <div class="frame">
        <div class="frame__hint">
          {{ hint.text }}
        </div>
        <component
          :is="component"
          :face-color="colors.face"
          class="frame__svg"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  reactive,
  computed,
  markRaw,
  ref,
  onMounted,
  getCurrentInstance,
} from "vue";
import { getFrameResolution } from "@/utils/resolution";
import HeadForwardAnimation from "@/components/svg/animation/HeadForwardAnimation.vue";
import HeadLeftAnimation from "@/components/svg/animation/HeadLeftAnimation.vue";
import HeadRightAnimation from "@/components/svg/animation/HeadRightAnimation.vue";

// eslint-disable-next-line no-undef
const props = defineProps({
  header: {
    type: String,
    required: true,
    default() {
      return "Default Header";
    },
    validator(header) {
      return header?.length > 0;
    },
  },
  hint: {
    type: String,
    default() {
      return "none";
    },
  },
  position: {
    type: String,
    required: true,
    default() {
      return "forward";
    },
    validator(position) {
      return ["forward", "left", "right"].includes(position);
    },
  },
  state: {
    type: String,
    default() {
      return "neutral";
    },
    validator(state) {
      return ["success", "failure", "neutral"].includes(state);
    },
  },
});

const positions = reactive({
  forward: {
    component: markRaw(HeadForwardAnimation),
  },
  left: {
    component: markRaw(HeadLeftAnimation),
  },
  right: {
    component: markRaw(HeadRightAnimation),
  },
});
const states = reactive({
  success: {
    colors: {
      border: "green",
      face: "green",
      background: "rgba(0, 128, 0, .2)",
    },
  },
  failure: {
    colors: {
      border: "red",
      face: "red",
      background: "rgba(255, 0, 0, .2)",
    },
  },
  neutral: {
    colors: {
      border: "white",
      face: "white",
      background: "rgba(255, 255, 255, .2)",
    },
  },
});
const hints = reactive({
  none: {
    text: "",
    background: "transparent",
  },
  "not-found": {
    text: "Лицо не найдено",
    background: "rgba(255, 0, 0, .7)",
  },
  "too-far": {
    text: "Слишком далеко, встаньте немного ближе",
    background: "rgba(254, 150, 62, .7)",
  },
  "too-close": {
    text: "Слишком близко, встаньте немнго дальше",
    background: "rgba(254, 150, 62, .7)",
  },
});

const component = computed(() => positions[props.position].component);
const colors = computed(() => states[props.state].colors);
const hint = computed(() => hints[props.hint]);

const size = ref({
  width: {
    default: "0px",
  },
  height: {
    default: "0px",
  },
});
onMounted(() => {
  const instance = getCurrentInstance().appContext.app._container;
  size.value = getFrameResolution(instance);
});
</script>

<style scoped>
.container {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  gap: 0 !important;
}

.background {
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.header {
  padding: 8px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  border-radius: 10px;
  transition: all 1s;
  z-index: 2;
}

.frame {
  position: relative;
}

.frame__hint {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 99%;
  height: 10%;
  text-align: center;
  color: #fff;
  background-color: v-bind(hint[ "background"]);
}

.frame__svg {
  display: block;
  border-radius: 60% / 50%;
  box-shadow: 0 0 0 100vw rgba(255, 255, 255, 0.9);
  transition: all 1s;
  border: 3px solid v-bind(colors[ "border"]);
  background: v-bind(colors[ "background"]);
  z-index: 1;
  height: v-bind("size.height.default");
  width: v-bind("size.width.default");
}

@media screen and (max-width: 650px) {
  .frame__svg {
    width: 250px !important;
    height: 350px !important;
  }

  .header {
    width: 80%;
  }
}
</style>
