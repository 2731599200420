<template>
  <div class="container">
    <div class="frame">
      <stepify-position-frame
        :ref="(ref) => (frame.ref = ref)"
        @finish="frame.finish"
      />
    </div>
    <video-component
      :ref="(ref) => (video.ref = ref)"
      @start="video.start"
      @stop="video.stop"
      @error="video.stop"
    />
  </div>
</template>

<script setup>
import { reactive, onBeforeUnmount, onBeforeMount } from "vue";
import StepifyPositionFrame from "@/components/position/StepifyPositionFrame.vue";
import VideoComponent from "@/components/common/VideoComponent.vue";
import useLoop from "@/utils/loop";
import { abortFlow, nextStep } from "@/utils/flow";
import { useSessionStore } from "@/plugins/pinia/stores/session";
import liveness from "@/plugins/axios/http/liveness";
import { useUserStore } from "@/plugins/pinia/stores/user";
import { createSession } from "@/plugins/axios/http/sessions";

const sessionStore = useSessionStore();
const userStore = useUserStore();
let session;

const loop = useLoop({
  interval: 1000,
  delay: 1000,
});

const frame = reactive({
  ref: null,
  async finish(status) {
    if (status === "success") {
      loop.stop();
      const livenessResult = await liveness.getResult(session);

      if (livenessResult?.data?.result) {
        userStore.setLivenessResult({
          status: livenessResult?.data?.result,
          data: livenessResult.data,
        });
        await nextStep();
      } else {
        await abortFlow();
      }
    } else {
      await abortFlow();
    }
  },
});
const video = reactive({
  ref: null,
  async start() {
    await loop.start(async () => {
      const response = await liveness.getPosition(
        session,
        video.ref.screenshot({
          quality: 0.6,
          type: "file",
        })
      );
      if (!loop.isWorking()) return;
      framing(response);
    });
  },
  stop() {
    abortFlow();
  },
});

onBeforeMount(async () => {
  session = (await createSession()).data.id;
  sessionStore.session = session;
});

onBeforeUnmount(() => {
  loop.stop();
});

function framing(response) {
  frame.ref.next(response.data.direction);
}
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.frame {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
</style>
