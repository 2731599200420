<script setup>
import { useUserStore } from "@/plugins/pinia/stores/user";
import SuccessMark from "@/components/result/svg/SuccessMark.vue";
import FailureMark from "@/components/result/svg/FailureMark.vue";
import { computed } from "vue";

const userStore = useUserStore();

const results = {
  success: {
    statusImage: SuccessMark,
    header: "Верификация пройдена",
    buttonText: "Закрыть",
  },
  failure: {
    statusImage: FailureMark,
    header: "Верификация не пройдена",
    buttonText: "Закрыть",
  },
};
const isLVResultExist = typeof userStore.livenessResult.status === "boolean";
const isF2FResultExist = typeof userStore.F2FResult.status === "boolean";
const LVResultStatus = userStore.livenessResult.status;
const F2FResultStauts = userStore.F2FResult.status;

const status = computed(() => {
  if (isLVResultExist && LVResultStatus && !isF2FResultExist) return true;
  if (isF2FResultExist && F2FResultStauts && !isLVResultExist) return true;
  return !!(
    isF2FResultExist &&
    isLVResultExist &&
    F2FResultStauts &&
    LVResultStatus
  );
});
const current = results[status.value ? "success" : "failure"];

const restartFlow = () => {
  userStore.resetStore();

  console.log(
    "тут можно что-то делать на окончание флоу на кнопку, или поставить на onMounted"
  );
};
</script>

<template>
  <div class="result-page">
    <component :is="current.statusImage" class="status-image"></component>
    <h2 class="header">{{ current.header }}</h2>
    <p-button :label="current.buttonText" @click="restartFlow" />
  </div>
</template>

<style scoped>
.result-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  gap: 20px;
}

.status-image {
  width: 100px;
  height: 100px;
}
</style>
