<template>
  <svg
    width="160"
    height="105"
    viewBox="0 0 160 105"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M109.999 56.014L109.5 79.0001L114 80.5001L126.5 81.5001L125.843 47.5066C124.5 21.5001 122.5 13.4479 112.843 6.50662C107.509 2.67329 93.7425 -3.49338 81.3426 2.50663C76.0092 1.33996 66 1.50012 61.5 11.0001C59.8333 17.6668 60.3432 29.5066 68.3432 32.5066C68.6765 31.8399 68.9994 29.814 68.9994 27.014C69.6661 26.1807 73.9994 24.414 85.9994 24.014C91.1661 23.8473 101.499 25.114 101.499 31.514C101.499 34.3473 102.499 40.214 106.499 41.014L109.5 41.5001C111.5 38.5001 114.1 33.7001 116.5 38.5001C117.167 43.3335 117.199 53.614 109.999 56.014Z"
      fill="#292B3A"
    />
    <path
      d="M101.5 31.5001C101.5 25.0001 91.1854 23.8467 85.9994 24.014C73.9998 24.4011 69.4994 26.0141 68.9994 27.014C68.4994 28.014 69.1432 30.5066 68.3432 32.5066L68.5 53.5001C68.6667 58.6668 72.1 69.4001 84.5 71.0001V79.0001C85 83.5001 95 87.5001 109.5 79.0001L109.999 56.014C117.211 53.6101 117.162 43.3028 116.5 38.5001C114.1 33.7003 111.532 38.5001 109.5 41.5001C108 41.2571 106.499 41.014 106.499 41.014C102.5 40.2141 101.5 32.8587 101.5 31.5001Z"
      fill="#F2B8A0"
    />
    <path
      d="M109.499 78.5001C101.998 83.5001 85.4985 85.0001 84.4986 78.5001L79.9986 79.5001C80 92.0001 103.999 90.5001 113.999 80.5001L109.499 78.5001Z"
      fill="#A5ADAF"
    />
    <path
      d="M53.4988 103C53.1654 95.1668 55.9979 79.5001 79.9986 79.5001C80 92.0001 104 90.5001 113.999 80.5001C128.999 80.0001 142.498 84.0001 142.998 103H53.4988Z"
      fill="#F5F5F3"
    />
    <g filter="url(#filter0_f_602_316)">
      <path
        d="M80 44.0001H78.1939V46.8001C76.5383 47.6001 74.2205 49.7601 78.1939 52.0001L80 50.8001C78.7959 50.0001 77.1103 48.1601 80 47.2001V44.0001Z"
        fill="#FFE9DD"
      />
      <path
        d="M80 44.0001H78.1939V46.8001C76.5383 47.6001 74.2205 49.7601 78.1939 52.0001L80 50.8001C78.7959 50.0001 77.1103 48.1601 80 47.2001V44.0001Z"
        stroke="#FFE9DD"
        stroke-linejoin="round"
      />
    </g>
    <path d="M31.5 69.0001H22L27 103H44L31.5 69.0001Z" fill="#F2B8A0" />
    <path
      d="M32 42.0001H21C20 42.0001 18 42.9001 18 46.5001V63.0001C18 64.3334 18.3708 66.457 21.5 67.5001C22.9804 67.9936 34.5 66.5001 35 62.0001C35.5 57.5001 35 45.0001 35 45.0001C35 43.0001 34 42.0001 32 42.0001Z"
      fill="#F2B8A0"
    />
    <path
      d="M21 33.5001V42.0001H32C33 42.0001 35 42.5001 35 45.0001C35 49.0001 35.5 57.5001 35 62.0001C34.5 66.5001 23 68.0001 21.5 67.5001V68.5001C22 68.5001 35.5 68.5001 35.5 68.5001C36.5 68.5001 37.5 68.0001 37.5 67.0001V33.5001C37.5 32.5001 37.5 31.0001 35.5 31.0001H22.5C22 31.0001 21 31.5001 21 33.5001Z"
      fill="#C8D8E5"
    />
    <path
      d="M38.5 33.5001C38.5 32.6668 37.5 31.0001 35.5 31.0001C37.5 31.0001 37.5 32.5001 37.5 33.5001V67.0001C37.5 68.0001 36.5 68.5001 35.5 68.5001C35.5 68.5001 22 68.5001 21.5 68.5001C21.5 68.5001 21.5 69.0001 22 69.0001L36 69.0001C36.8333 69.0001 38.5 68.6001 38.5 67.0001V33.5001Z"
      fill="#ADB9C5"
    />
    <path
      d="M34.0002 33.0001C36.6942 33.0001 36.4999 37.5001 34.0002 37.5001C31.5005 37.5001 31 33.0001 34.0002 33.0001Z"
      fill="#383844"
    />
    <path
      d="M158 103H2.00025C0.499743 103 0.500093 105 2.00025 105H158C159.5 105 159.5 103 158 103Z"
      fill="#F5F5F3"
    />
    <g filter="url(#filter1_f_602_316)">
      <path
        d="M28.5 13L23.5 8L20.5 11L28.5 19L43.5 4L40.5 1L28.5 13Z"
        fill="#9AD442"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_602_316"
        x="72.5"
        y="40.5001"
        width="11"
        height="15"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="1.5"
          result="effect1_foregroundBlur_602_316"
        />
      </filter>
      <filter
        id="filter1_f_602_316"
        x="20.4"
        y="0.9"
        width="23.2"
        height="18.2"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="0.05"
          result="effect1_foregroundBlur_602_316"
        />
      </filter>
    </defs>
  </svg>
</template>
