<template>
  <svg
    width="159"
    height="105"
    viewBox="0 0 159 105"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M61 79.5C42.5 80 32.5 85 31 103H125.5C124 89.5 120 79.5 96 79.5C87.5 91 65.5 91.5 61 79.5Z"
      fill="#F5F5F3"
    />
    <path
      d="M65.5 78L61 79.5C65.5 91.5 87.5 91 96 79.5L91.5 78C85 86 70.5 86 65.5 78Z"
      fill="#A5ADAF"
    />
    <path
      d="M65.4999 70L65.5 78C70.5 86 85 86 91.5 78L91.4992 70C96.4992 65 98.9992 63 101.499 55.5C105.999 53 107.999 42.5 101.499 45V32.9999C96.9992 22.5 79.4992 17.5 54.9999 30.9999V45C47.5004 42.5 52.0007 55.4999 55.4999 56C55.4999 60 60.9999 68 65.4999 70Z"
      fill="#F2B8A0"
    />
    <path
      d="M51.5 22.5V45.5C51.8334 45 52.9999 44.2 54.9999 45V30.9999C79.4991 17.5 97 22.5 101.5 33V45C101.667 44.8333 103 44.5 104 44.8027V18.5C104 14 99.0003 5.99999 93.5003 5.99999H87.0003C85.0003 4.00005 76.0003 -0.500041 71.0003 4.00003C63.0003 0.999992 53.7003 4.50003 50.5003 8.50003L51.5 18.5001C49.5003 17.5 49.5004 19.0001 51.5 22.5Z"
      fill="#292B3A"
    />
    <g filter="url(#filter0_f_608_431)">
      <path
        d="M79.4995 45.5C78.9995 45 77.7994 44.3 76.9994 45.5L77.0002 49C75.4995 49 73.9986 52.5 75.9994 53L75.9989 54.5C75.9989 54.5 77.2994 55.7 78.4994 54.5V53C76.9994 52.5 75.9995 51.5 79.4995 49V45.5Z"
        fill="#FDE0D2"
      />
    </g>
    <path
      d="M157.125 103H1.12525C-0.375257 103 -0.374907 105 1.12525 105H157.125C158.625 105 158.625 103 157.125 103Z"
      fill="#F5F5F3"
    />
    <g filter="url(#filter1_f_608_431)">
      <path d="M26 13L21 8L18 11L26 19L41 4L38 1L26 13Z" fill="#9AD442" />
    </g>
    <defs>
      <filter
        id="filter0_f_608_431"
        x="72.0352"
        y="41.8374"
        width="10.4648"
        height="16.1959"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="1.5"
          result="effect1_foregroundBlur_608_431"
        />
      </filter>
      <filter
        id="filter1_f_608_431"
        x="17.9"
        y="0.9"
        width="23.2"
        height="18.2"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="0.05"
          result="effect1_foregroundBlur_608_431"
        />
      </filter>
    </defs>
  </svg>
</template>
