<template>
  <div :class="$style['wrapper']">
    <liveness-instruction
      :svg="firstInstruction.svgNumber"
      :text="firstInstruction.helpText"
    />
    <liveness-instruction
      :svg="secondInstruction.svgNumber"
      :text="secondInstruction.helpText"
    />
    <p-button :label="button.text" @click="button.action()" />
  </div>
</template>

<script setup>
import LivenessInstruction from "@/components/instructions/LivenessInstruction.vue";
import { markRaw } from "vue";
import { nextStep } from "@/utils/flow";

const firstInstruction = {
  svgNumber: 1,
  helpText: "Держите камеру на уровне лица",
};
const secondInstruction = {
  svgNumber: 2,
  helpText:
    "Снимите головные уборы и аксесуары, убедитесь в равномерном освещении лица",
};
const button = {
  text: "Продолжить",
  action: markRaw(() => {
    nextStep();
  }),
};
</script>

<style module>
.wrapper {
  width: 100%;
  min-height: 500px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.language-switcher {
  position: absolute;
  top: 1%;
  right: 1%;
}
</style>
