import { createRouter, createWebHistory } from "vue-router";
import PositionPage from "@/pages/PositionPage.vue";
import DistancePage from "@/pages/DistancePage.vue";
import LivenessInstruction from "@/pages/InstructionPage.vue";
import ResultPage from "@/pages/ResultPage.vue";

const routes = [
  {
    path: "/position",
    name: "Position Page",
    component: PositionPage,
  },
  {
    path: "/distance",
    name: "Distance Page",
    component: DistancePage,
  },
  {
    path: "/instruction",
    name: "Instruction Page",
    component: LivenessInstruction,
  },
  {
    path: "/result",
    name: "Result Page",
    component: ResultPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
export default router;
