import { createApp } from "vue";
import App from "./App.vue";
import router from "@/plugins/router";
import PrimeVue from "primevue/config";
import pinia from "@/plugins/pinia";
import PButton from "primevue/button";
import PDataTable from "primevue/datatable";
import PColumn from "primevue/column";
import PDropdown from "primevue/dropdown";
import PFileUpload from "primevue/fileupload";
import PToast from "primevue/toast";
import PDialog from "primevue/dialog";
import ToastService from "primevue/toastservice";
const app = createApp(App);

app.use(router);
app.use(pinia);
app.use(PrimeVue);
app.use(ToastService);

app.component("PButton", PButton);
app.component("PDataTable", PDataTable);
app.component("PColumn", PColumn);
app.component("PDropdown", PDropdown);
app.component("PFileUpload", PFileUpload);
app.component("PToast", PToast);
app.component("PDialog", PDialog);

app.mount("#app");
