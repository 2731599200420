import { instance } from "@/plugins/axios";

export default {
  async getPosition(session, image) {
    const fd = new FormData();
    fd.append("session_id", session);
    fd.append("face_photo", image);

    return await instance
      .post("/flow/liveness/head-estimation/", fd)
      .catch(() => {});
  },

  async getDistance(session, image) {
    const fd = new FormData();
    fd.append("session_id", session);
    fd.append("face_photo", image);

    return await instance.post("/flow/liveness/distance/", fd);
  },

  async getDetect(session, image) {
    const fd = new FormData();
    fd.append("session_id", session);
    fd.append("face_photo", image);

    return await instance.post("/flow/liveness/detect/", fd);
  },

  async getBatch(session, image) {
    const fd = new FormData();
    fd.append("session_id", session);
    fd.append("face_photo", image);

    return await instance.post("/flow/liveness/detect/batch/", fd);
  },

  async getResult(session) {
    const fd = {
      session_id: session,
    };

    return await instance.post("/flow/liveness/result/", fd);
  },
};
