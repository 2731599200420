import { pause } from "@/utils/time";

export default function useLoop(config) {
  const options = {
    delay: config?.delay ?? 0,
    interval: config?.interval ?? 1000,
    working: false,
  };

  return {
    async start(callback) {
      options.working = true;
      await pause(options.delay);

      // eslint-disable-next-line no-constant-condition
      while (true) {
        if (!options.working) break;
        await callback();
        await pause(options.interval);
      }
    },
    stop() {
      options.working = false;
    },
    isWorking() {
      return options.working;
    },
  };
}
