<template>
  <div class="container">
    <div class="background">
      <p class="header">
        {{ props.header }}
      </p>
      <div class="frame">
        <div class="frame__hint">
          {{ hint.text }}
        </div>
        <div
          :class="{
            'svg-container': true,
            'svg-container_big': props.size === 'big',
            'svg-container_small': props.size === 'small',
          }"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, computed, onMounted, getCurrentInstance, ref } from "vue";
import { getFrameResolution } from "@/utils/resolution";

// eslint-disable-next-line no-undef
const props = defineProps({
  header: {
    type: String,
    required: true,
    default() {
      return "Default Header";
    },
    validator(header) {
      return header?.length > 0;
    },
  },
  hint: {
    type: String,
    default() {
      return "none";
    },
  },
  size: {
    type: String,
    required: true,
    default() {
      return "big";
    },
    validator(size) {
      return ["small", "big"].includes(size);
    },
  },
  state: {
    type: String,
    default() {
      return "neutral";
    },
    validator(state) {
      return ["success", "failure", "neutral"].includes(state);
    },
  },
});

const states = reactive({
  success: {
    colors: {
      border: "green",
      face: "green",
      background: "rgba(0, 128, 0, .2)",
      textColor: "#fff",
    },
  },
  failure: {
    colors: {
      border: "red",
      face: "red",
      background: "rgba(255, 0, 0, .2)",
      textColor: "#fff",
    },
  },
  neutral: {
    colors: {
      border: "white",
      face: "white",
      background: "rgba(255, 255, 255, .2)",
      textColor: "#fff",
    },
  },
});
const hints = reactive({
  none: {
    text: "",
    background: "transparent",
  },
  "not-found": {
    text: "Лицо не найдено",
    background: "rgba(255, 0, 0, .7)",
  },
  "too-far": {
    text: "Слишком далеко, встаньте ближе",
    background: "rgba(254, 150, 62, .7)",
  },
  "too-close": {
    text: "Слишком близко, встаньте дальше",
    background: "rgba(254, 150, 62, .7)",
  },
});

const colors = computed(() => states[props.state].colors);
const hint = computed(() => hints[props.hint]);

const size = ref({
  width: {
    further: "0px",
    closer: "0px",
  },
  height: {
    further: "0px",
    closer: "0px",
  },
});

const mutateAndFormat = (dimension, multiplier) => {
  for (const key in dimension) {
    let value = parseFloat(dimension[key]);
    value -= value * multiplier;
    dimension[key] = `${value}px`;
  }
};

onMounted(() => {
  const instance = getCurrentInstance().appContext.app._container;
  size.value = getFrameResolution(instance);
  mutateAndFormat(size.value.width, 0.3);
  mutateAndFormat(size.value.height, 0.15);
});
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.background {
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.header {
  padding: 8px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  border-radius: 10px;
  z-index: 2;
  transition: 1s all;
}

.frame {
  position: relative;
}

.frame__hint {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 99%;
  height: 10%;
  text-align: center;
  color: #fff;
  background-color: v-bind(hint [ "background"]);
}

.svg-container {
  display: block;
  margin-top: 10px;
  width: 100%;
  height: 100%;
  border: 5px solid v-bind(colors [ "border"]);
  color: v-bind(colors[ "textColor"]);
  border-radius: 60% / 50%;
  box-shadow: 0 0 0 100vw rgba(255, 255, 255, 0.9);
  overflow: visible !important;
  transition: 1s all;
}

.svg-container_big {
  height: v-bind("size.height.closer");
  width: v-bind("size.width.closer");
}

.svg-container_small {
  height: v-bind("size.height.further");
  width: v-bind("size.width.further");
}

@media screen and (max-width: 650px) {
  .header {
    width: 80%;
  }

  .background {
    width: 100vw;
    height: 100vh;
  }
}
</style>
